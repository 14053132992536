import React from 'react'
import classnames from 'classnames/bind'
import styles from './Cart.module.css'

const cx = classnames.bind(styles)

export default () => {
    return (
        <div className={cx('container-all')}>
        </div>
    )
}